import React from 'react';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import moment from 'moment';

import { TransactionTable } from '../../custom';
import { transactions } from '../../../state';

const FundsRaised = ({ getOrgTransactions, orgTransactions, isLoading }) => {
  React.useEffect(() => {
    getOrgTransactions();
  }, []);

  const cols = ['Transaction Date', 'Merchant Name', 'Amount Raised'];
  const rowKeys = ['transactionDate', 'merchantName', 'amountRaised'];
  const mods = {
    transactionDate: (val) => moment(val).format('MMMM DD, YYYY'),
    amountRaised: (val) => `$${val.toFixed(2)}`,
  };

  return (
    <Grid container spacing={4}>
      <Grid container item xs={12}>
        <TransactionTable
          cols={cols}
          rowKeys={rowKeys}
          data={orgTransactions}
          mods={mods}
          isLoading={isLoading}
          noDataMsg="Hmm... Looks like there are no transactions yet."
        />
      </Grid>
    </Grid>
  );
};

FundsRaised.defaultProps = {
  orgTransactions: [],
};

FundsRaised.propTypes = {
  getOrgTransactions: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  orgTransactions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      amount: PropTypes.number,
      paidDate: PropTypes.string,
      confirmationNumber: PropTypes.string,
      organizationId: PropTypes.string,
      createdAt: PropTypes.string,
      paymentType: PropTypes.string,
    })
  ),
};

const mapStateToProps = (state) => ({
  orgTransactions: transactions.selectors.selectOrgTransactions(state),
  isLoading: transactions.selectors.selectIsLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
  getOrgTransactions: () => dispatch(transactions.actions.getOrgTransactions()),
});

export default connect(mapStateToProps, mapDispatchToProps)(FundsRaised);
