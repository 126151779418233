import React from 'react';
import { Typography, Box } from '@material-ui/core';

import SEO from '../../seo';
import ComingSoon from '../ComingSoon';

const Reports = () => {
  return (
    <>
      <SEO title="Reports" />
      <Box pb={2}>
        <Typography variant="h5">Reports</Typography>
      </Box>
      <Box py={2}>
        <ComingSoon />
      </Box>
    </>
  );
};

export default Reports;
