import React from 'react';
import {
  Box,
  TextField,
  withStyles,
  Typography,
  Button,
  Grid,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { alert, org } from '../../../state';

const StyledTextField = withStyles((theme) => ({
  root: {
    '& .Mui-disabled': {
      color: theme.palette.primary.main,
      cursor: 'text',
    },
  },
}))(TextField);

const url = 'http://app.pintuna.com';

const apps = [
  'Twitter',
  'Instagram',
  'Nextdoor',
  'Facebook',
  'Website',
  'Email',
];

const LinkSection = () => {
  const dispatch = useDispatch();
  const { code: orgID } = useSelector((state) =>
    org.selectors.selectOrgDetails(state)
  );

  const allLinks = apps
    .map((app) => `${url}/?org=${orgID}&src=${app.toLowerCase()}`)
    .join(`, `);

  const handleCopy = () => {
    const payload = {
      message: 'Copied to your clipboard',
      severity: 'success',
    };
    dispatch(alert.actions.open(payload));
  };

  return (
    <Box>
      <Box pb={2}>
        <Typography variant="body1">Direct Links</Typography>
      </Box>
      <Box pb={2}>
        {apps.map((app) => {
          const appLink = `${url}/?org=${orgID}&src=${app.toLowerCase()}`;

          return (
            <Box key={app} pb={2}>
              <Grid container direction="row" alignItems="flex-end">
                <Grid item xs={8} md={9}>
                  <StyledTextField
                    fullWidth
                    label={app}
                    defaultValue={appLink}
                    disabled
                  />
                </Grid>
                <Grid item xs={4} md={3}>
                  <Box pl={2} align="center">
                    <CopyToClipboard text={appLink} onCopy={handleCopy}>
                      <Button size="small" variant="outlined">
                        Copy
                      </Button>
                    </CopyToClipboard>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          );
        })}
      </Box>
      <CopyToClipboard text={allLinks} onCopy={handleCopy}>
        <Button variant="outlined">Copy All Links</Button>
      </CopyToClipboard>
    </Box>
  );
};

export default LinkSection;
