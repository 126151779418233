import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { navigate, useLocation } from '@reach/router';
import { auth } from '../state';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const location = useLocation();
  const isLoggedIn = useSelector((state) =>
    auth.selectors.selectIsLoggedIn(state)
  );

  if (!isLoggedIn && location.pathname !== `/dashboard/login`) {
    navigate('/dashboard/login');
    return null;
  }

  return <Component {...rest} />;
};

PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.node,
    PropTypes.any,
  ]).isRequired,
};

export default PrivateRoute;
