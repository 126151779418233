import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Grid,
  CircularProgress,
  Button,
  Box,
  Typography,
  Link,
} from '@material-ui/core';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import CustomInputField from '../../custom/CustomInputField';
import { OrganizationDetailsValidation } from './validation';
import LogoSection from './LogoSection';

import { org } from '../../../state';

const DetailsSection = ({ orgDetails }) => {
  const { taxId, website, name, imageUrl } = orgDetails;

  return (
    <Grid container direction="row" justify="flex-start" alignItems="center">
      <Grid item xs={12} md={4}>
        <Box pb={3}>
          <LogoSection imageUrl={imageUrl} name={name} />
        </Box>
        <Formik
          enableReinitialize
          validationSchema={OrganizationDetailsValidation}
          initialValues={{
            name: name || '',
            website: website || '',
            taxId: taxId || '',
            guidestar: '',
          }}
          onSubmit={() => {
            // console.log(values);
          }}
        >
          {({ isSubmitting, handleSubmit }) => {
            return (
              <Form>
                <Box pb={2}>
                  <CustomInputField
                    customInput={Field}
                    component={TextField}
                    capitalize
                    fullWidth
                    label="Nonprofit Name"
                    name="name"
                    variant="outlined"
                    disabled
                  />
                </Box>
                <Box pb={2}>
                  <CustomInputField
                    customInput={Field}
                    component={TextField}
                    capitalize
                    fullWidth
                    label="Website"
                    name="website"
                    variant="outlined"
                    disabled
                  />
                </Box>
                <Box pb={2}>
                  <CustomInputField
                    customInput={Field}
                    component={TextField}
                    trim
                    fullWidth
                    label="Nonprofit Tax id"
                    name="taxId"
                    variant="outlined"
                    disabled
                  />
                </Box>
                {/* <Box pb={2}>
                  <CustomInputField
                    customInput={Field}
                    component={TextField}
                    fullWidth
                    label="Link to Guidestar (if applicable)"
                    name="guidestar"
                    variant="outlined"
                    disabled
                  />
                </Box> */}
                <Box pb={2}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled
                    onClick={handleSubmit}
                    size="large"
                    fullWidth
                  >
                    {isSubmitting ? (
                      <CircularProgress size={24} />
                    ) : (
                      'Save Details'
                    )}
                  </Button>
                  <Box align="center">
                    <Typography variant="caption">
                      <Link
                        href="mailto:info@pintuna.com"
                        style={{ color: 'blue' }}
                      >
                        Contact Us
                      </Link>
                      {` `}for any changes.
                    </Typography>
                  </Box>
                </Box>
              </Form>
            );
          }}
        </Formik>
      </Grid>
    </Grid>
  );
};

DetailsSection.propTypes = {
  orgDetails: PropTypes.shape({
    taxId: PropTypes.string,
    website: PropTypes.string,
    name: PropTypes.string,
    imageUrl: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  orgDetails: org.selectors.selectOrgDetails(state),
});

export default connect(mapStateToProps)(DetailsSection);
