import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import {
  Typography,
  Grid,
  Divider,
  Box,
  Tabs,
  Tab,
  withStyles,
} from '@material-ui/core';

// import UserManagementSection from './UserManagementSection';
import DetailsSection from './DetailsSection';
import AddressSection from './AddressSection';
import BankInfoSection from './BankInfoSection';

import { auth } from '../../../state';
import SEO from '../../seo';

// move to a separate component
const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box py={3}>{children}</Box>}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.oneOfType([PropTypes.any]).isRequired,
  value: PropTypes.oneOfType([PropTypes.any]).isRequired,
};

const a11yProps = (index) => {
  return {
    id: `${index}`,
    'aria-controls': `${index}`,
  };
};

const StyledTab = withStyles({
  root: {
    minWidth: 'auto',
    marginRight: '20px',
    padding: '0',
    textTransform: 'capitalize',
  },
})(Tab);

const Profile = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <Grid container direction="row" justify="center" alignItems="center">
      <Grid item xs={12}>
        <SEO title="Profile" />
        <Box pb={2}>
          <Typography variant="h5">Profile</Typography>
        </Box>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          aria-label="Select settings tab"
        >
          <StyledTab disableRipple label="Details" {...a11yProps('Details')} />
          {/* <StyledTab
            disableRipple
            label="Manage Users"
            {...a11yProps('ManageUsers')}
          /> */}
          <StyledTab disableRipple label="Address" {...a11yProps('Address')} />
          <StyledTab disableRipple label="Banking" {...a11yProps('Banking')} />
        </Tabs>
        <Divider />
        <SwipeableViews index={value} onChangeIndex={handleChangeIndex}>
          <TabPanel value={value} index={0}>
            <DetailsSection />
          </TabPanel>
          {/* <TabPanel value={value} index={1}>
            <UserManagementSection />
          </TabPanel> */}
          <TabPanel value={value} index={1}>
            <AddressSection />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <BankInfoSection />
          </TabPanel>
        </SwipeableViews>
      </Grid>
    </Grid>
  );
};
Profile.defaultProps = {};

Profile.propTypes = {
  user: PropTypes.shape({
    firstName: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  user: auth.selectors.selectUser(state),
});
export default connect(mapStateToProps)(Profile);
