import React from 'react';
import { CardMedia, Box, Typography } from '@material-ui/core';
import { Link } from 'gatsby';

import SEO from '../../seo';
import { SignInForm } from '../../Auth';
import { Header, Content } from '../../layouts';
import logoPath from '../../../images/logo.png';

const Login = () => {
  return (
    <>
      <SEO title="Nonprofit Login" />
      <Header
        backArrow
        href="https://org.pintuna-staging.com/dashboard/login"
      />
      <Content md={4} alignMiddle>
        <Box pb={2} align="center">
          <Link to="/">
            <CardMedia
              src={logoPath}
              component="img"
              alt="Pintuna logo"
              style={{ width: '200px' }}
            />
          </Link>
        </Box>
        <Box py={2} align="center">
          <Typography variant="body2" color="textSecondary">
            Sign in to access your dashboard
          </Typography>
        </Box>
        <SignInForm />
      </Content>
    </>
  );
};

export default Login;
