import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { getOr } from 'lodash/fp';
import {
  AttachMoney,
  AccountBalance,
  AccountBalanceWallet,
} from '@material-ui/icons';
import { InfoCard } from '../../custom';

const InfoSection = ({ isLoading, orgStats, handleScreen }) => {
  const fundsRaised = getOr(0, 'totalFundsRaised', orgStats).toFixed(2);
  const payoutAmount = getOr(0, 'totalPayoutAmount', orgStats).toFixed(2);
  const balance = getOr(0, 'balance', orgStats).toFixed(2);

  return (
    <Grid container spacing={4}>
      <Grid container item xs={12} md={4}>
        <InfoCard
          label="TOTAL FUNDS RAISED"
          icon={<AttachMoney fontSize="large" />}
          isLoading={isLoading}
          disabled={isLoading}
          clickable
          onClick={() => handleScreen({ raised: true })}
        >
          ${fundsRaised}
        </InfoCard>
      </Grid>
      <Grid container item xs={12} md={4}>
        <InfoCard
          label="TOTAL PAYOUT AMOUNT"
          icon={<AccountBalance fontSize="large" />}
          isLoading={isLoading}
          disabled={isLoading}
          clickable
          onClick={() => handleScreen({ payout: true })}
        >
          ${payoutAmount}
        </InfoCard>
      </Grid>
      <Grid container item xs={12} md={4}>
        <InfoCard
          label="BALANCE"
          icon={<AccountBalanceWallet fontSize="large" />}
          isLoading={isLoading}
        >
          ${balance}
        </InfoCard>
      </Grid>
    </Grid>
  );
};

InfoSection.propTypes = {
  handleScreen: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  orgStats: PropTypes.shape({
    totalFundsRaised: PropTypes.number,
    totalPayoutAmount: PropTypes.number,
    balance: PropTypes.number,
  }).isRequired,
};

export default InfoSection;
