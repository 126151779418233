import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Typography, Divider, Box, Grid, IconButton } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';

import { stats } from '../../../state';
import SEO from '../../seo';
import InfoSection from './InfoSection';
import ChartSection from './ChartSection';
import FundsRaised from './FundsRaised';
import Payouts from './Payouts';

const Dashboard = ({ orgStats, getStats, isLoading }) => {
  const defaults = {
    chart: false,
    payout: false,
    raised: false,
  };
  const [screen, setScreen] = React.useState(defaults);

  React.useEffect(() => {
    setScreen({ ...defaults, chart: true });
    getStats();
  }, []);

  const handleScreen = (newScreen) => {
    setScreen({ ...defaults, ...newScreen });
  };

  return (
    <>
      <SEO title="Dashboard" />
      <Box pb={2}>
        <Grid container alignItems="center" spacing={2}>
          {!screen.chart && (
            <Grid item>
              <IconButton
                aria-label="Back"
                onClick={() => handleScreen({ chart: true })}
                style={{ padding: 6 }}
              >
                <ArrowBack fontSize="small" />
              </IconButton>
            </Grid>
          )}
          <Grid item>
            <Typography variant="h5">
              {screen.chart && 'Dashboard'}
              {screen.payout && 'Total Payout Amount'}
              {screen.raised && 'Total Funds Raised'}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Divider />
      <Box py={2}>
        <InfoSection
          isLoading={isLoading}
          orgStats={orgStats}
          handleScreen={handleScreen}
        />
      </Box>
      <Box py={2}>
        {screen.chart && (
          <ChartSection isLoading={isLoading} orgStats={orgStats} />
        )}
        {screen.payout && <Payouts />}
        {screen.raised && <FundsRaised />}
      </Box>
    </>
  );
};

Dashboard.propTypes = {
  getStats: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  orgStats: PropTypes.shape({
    totalFundsRaised: PropTypes.number,
    totalPayoutAmount: PropTypes.number,
    balance: PropTypes.number,
    topFiveMerchants: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        cardsSold: PropTypes.number,
      })
    ),
    lastFiveMonths: PropTypes.arrayOf(
      PropTypes.shape({
        month: PropTypes.string,
        cardsSold: PropTypes.number,
      })
    ),
  }).isRequired,
};

const mapStateToProps = (state) => ({
  orgStats: stats.selectors.selectOrgStats(state),
  isLoading: stats.selectors.selectIsLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
  getStats: () => dispatch(stats.actions.getOrgStats()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
