import React from 'react';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import moment from 'moment';
import { TransactionTable } from '../../custom';
import { payouts } from '../../../state';

const Payouts = ({ orgPayouts, getOrgPayouts, isLoading }) => {
  React.useEffect(() => {
    getOrgPayouts();
  }, []);

  const cols = ['Payout Date', 'Amount', 'Payment Type', 'Confirmation Number'];
  const rowKeys = ['paidDate', 'amount', 'paymentType', 'confirmationNumber'];
  const mods = {
    paidDate: (val) => moment(val).format('MMMM DD, YYYY'),
    amount: (val) => `$${val.toFixed(2)}`,
  };

  return (
    <Grid container spacing={4}>
      <Grid container item xs={12}>
        <TransactionTable
          cols={cols}
          rowKeys={rowKeys}
          data={orgPayouts}
          mods={mods}
          isLoading={isLoading}
          noDataMsg="Hmm... Looks like there are no payouts yet."
        />
      </Grid>
    </Grid>
  );
};

Payouts.defaultProps = {
  orgPayouts: [],
};

Payouts.propTypes = {
  getOrgPayouts: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  orgPayouts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      amount: PropTypes.number,
      paidDate: PropTypes.string,
      confirmationNumber: PropTypes.string,
      organizationId: PropTypes.string,
      createdAt: PropTypes.string,
      paymentType: PropTypes.string,
    })
  ),
};

const mapStateToProps = (state) => ({
  orgPayouts: payouts.selectors.selectOrgPayouts(state),
  isLoading: payouts.selectors.selectIsLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
  getOrgPayouts: () => dispatch(payouts.actions.getOrgPayouts()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Payouts);
