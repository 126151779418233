import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Avatar,
  makeStyles,
  Box,
  Button,
  Typography,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
}));

const LogoSection = ({ imageUrl, name }) => {
  const classes = useStyles();
  return (
    <Grid container direction="row" justify="center" alignItems="center">
      <Grid item xs={12} sm={8}>
        <Box
          flexDirection="row"
          display="flex"
          alignItems="center"
          pb={{ xs: 3, sm: 0 }}
        >
          <Box>
            <Avatar alt={name} src={imageUrl} className={classes.large} />
          </Box>
          <Box pl={1}>
            <Typography variant="body2">Your Logo</Typography>
            <Typography variant="caption" color="textSecondary">
              PNG or JPG (200px x 200px).
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Box align="right">
          <Button disabled fullWidth variant="outlined" color="primary">
            Upload
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

LogoSection.defaultProps = {
  name: '',
  imageUrl: '',
};

LogoSection.propTypes = {
  name: PropTypes.string,
  imageUrl: PropTypes.string,
};

export default LogoSection;
