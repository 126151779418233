import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Grid,
  CircularProgress,
  Button,
  Box,
  MenuItem,
} from '@material-ui/core';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import CustomInputField from '../../custom/CustomInputField';
import { BankInfoValidation } from './validation';
import { org, alert } from '../../../state';

const listPaymentTypes = ['Check', 'ACH'];

const BankInfoSection = ({ orgDetails, openAlert, updateBankDetails }) => {
  const [isACH, setIsACH] = React.useState(false);
  React.useEffect(() => {
    if (orgDetails.paymentType === 'ACH') {
      setIsACH(true);
    }
  }, []);

  const {
    bankName,
    accountNumber,
    routingNumber,
    paymentType,
    accountName,
  } = orgDetails;
  const handlePaymentTypeClick = (pType) => {
    if (pType === 'ACH') {
      return setIsACH(true);
    }
    return setIsACH(false);
  };

  return (
    <Grid container direction="row" justify="flex-start" alignItems="center">
      <Grid item xs={12} md={4}>
        <Formik
          enableReinitialize
          validationSchema={BankInfoValidation}
          initialValues={{
            bankName: bankName || '',
            accountNumber: accountNumber || '',
            routingNumber: routingNumber || '',
            paymentType: paymentType || 'Check',
            accountName: accountName || '',
          }}
          onSubmit={async (values, actions) => {
            const response = await updateBankDetails(values);

            if (response.payload) {
              openAlert({
                message: 'Your banking details were updated!',
                severity: 'success',
              });
            }
            if (response.error) {
              openAlert({
                message: 'Ooops, something went wrong.',
                severity: 'error',
              });
            }
            actions.setSubmitting(false);
          }}
        >
          {({ isSubmitting, handleSubmit, setFieldValue }) => {
            return (
              <Form>
                <Box pb={2}>
                  <Field
                    component={TextField}
                    label="Payment Type"
                    select
                    name="paymentType"
                    variant="outlined"
                    required
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                  >
                    {listPaymentTypes.map((type) => (
                      <MenuItem
                        key={type}
                        value={type}
                        onClick={() => {
                          setFieldValue('paymentType', type);
                          handlePaymentTypeClick(type);
                        }}
                      >
                        {type}
                      </MenuItem>
                    ))}
                  </Field>
                </Box>
                {isACH && (
                  <>
                    <Box pb={2}>
                      <CustomInputField
                        customInput={Field}
                        component={TextField}
                        capitalize
                        fullWidth
                        label="Bank name"
                        name="bankName"
                        variant="outlined"
                        required
                      />
                    </Box>
                    <Box pb={2}>
                      <CustomInputField
                        customInput={Field}
                        component={TextField}
                        capitalize
                        fullWidth
                        maskType="numbers"
                        label="Account number"
                        name="accountNumber"
                        variant="outlined"
                        required
                      />
                    </Box>
                    <Box pb={2}>
                      <CustomInputField
                        customInput={Field}
                        component={TextField}
                        fullWidth
                        maskType="numbers"
                        label="Routing number"
                        name="routingNumber"
                        variant="outlined"
                        required
                      />
                    </Box>
                    <Box pb={2}>
                      <CustomInputField
                        customInput={Field}
                        component={TextField}
                        fullWidth
                        capitalize
                        label="Account Name"
                        name="accountName"
                        variant="outlined"
                        required
                      />
                    </Box>
                  </>
                )}
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  onClick={handleSubmit}
                  size="large"
                  fullWidth
                >
                  {isSubmitting ? (
                    <CircularProgress size={24} />
                  ) : (
                    'Save Banking Details'
                  )}
                </Button>
              </Form>
            );
          }}
        </Formik>
      </Grid>
    </Grid>
  );
};

BankInfoSection.propTypes = {
  orgDetails: PropTypes.shape({
    bankName: PropTypes.string,
    accountNumber: PropTypes.string,
    routingNumber: PropTypes.string,
    paymentType: PropTypes.string,
    accountName: PropTypes.string,
  }).isRequired,
  updateBankDetails: PropTypes.func.isRequired,
  openAlert: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  orgDetails: org.selectors.selectOrgDetails(state),
});

const mapDispatchToProps = (dispatch) => ({
  openAlert: (payload) => dispatch(alert.actions.open(payload)),
  updateBankDetails: (payload) =>
    dispatch(org.actions.updateBankDetails(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BankInfoSection);
