import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  CircularProgress,
  Button,
  MenuItem,
  Box,
} from '@material-ui/core';
import { omit } from 'lodash/fp';
import { connect } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import CustomInputField from '../../custom/CustomInputField';
import { AddressValidation } from './validation';
import { org, alert } from '../../../state';
import listOfStates from '../../../../misc/listOfStates.json';

const AddressSection = ({ orgDetails, updateAddress, openAlert }) => {
  const { address, state, zip, city } = orgDetails;
  return (
    <Grid container direction="row" justify="flex-start" alignItems="center">
      <Grid item xs={12} md={4}>
        <Formik
          // enableReinitialize
          validationSchema={AddressValidation}
          initialValues={{
            address: address || '',
            city: city || '',
            state: state || '',
            zip: zip || '',
            country: 'United States',
          }}
          onSubmit={async (values, actions) => {
            const payload = omit(['country'], values);
            const response = await updateAddress(payload);

            if (response.payload) {
              openAlert({
                message: 'Your address has been updated',
                severity: 'success',
              });
            }
            if (response.error) {
              openAlert({
                message: 'Ooops, something went wrong.',
                severity: 'error',
              });
            }
            actions.setSubmitting(false);
          }}
        >
          {({ isSubmitting, handleSubmit, setFieldValue }) => {
            return (
              <Form>
                <Box pb={2}>
                  <CustomInputField
                    customInput={Field}
                    component={TextField}
                    capitalize
                    fullWidth
                    label="Address"
                    name="address"
                    variant="outlined"
                  />
                </Box>
                <Box pb={2}>
                  <Grid container display="row" spacing={2}>
                    <Grid item xs={6}>
                      <CustomInputField
                        customInput={Field}
                        component={TextField}
                        capitalize
                        fullWidth
                        label="City"
                        name="city"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Field
                        component={TextField}
                        label="State"
                        select
                        name="state"
                        variant="outlined"
                        required
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                      >
                        {Object.keys(listOfStates).map((usState) => (
                          <MenuItem
                            key={usState}
                            value={usState}
                            onClick={() => setFieldValue('state', usState)}
                          >
                            {usState}
                          </MenuItem>
                        ))}
                      </Field>
                    </Grid>
                    <Grid item xs={3}>
                      <CustomInputField
                        customInput={Field}
                        component={TextField}
                        fullWidth
                        label="Zip code"
                        name="zip"
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          inputProps: {
                            maxLength: '5',
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Box pt={2}>
                    <CustomInputField
                      customInput={Field}
                      component={TextField}
                      fullWidth
                      disabled
                      label="Country"
                      name="country"
                      variant="outlined"
                    />
                  </Box>
                </Box>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  onClick={handleSubmit}
                  size="large"
                  fullWidth
                >
                  {isSubmitting ? (
                    <CircularProgress size={24} />
                  ) : (
                    'Save Address'
                  )}
                </Button>
              </Form>
            );
          }}
        </Formik>
      </Grid>
    </Grid>
  );
};

AddressSection.propTypes = {
  orgDetails: PropTypes.shape({
    address: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.string,
    city: PropTypes.string,
  }).isRequired,
  updateAddress: PropTypes.func.isRequired,
  openAlert: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  orgDetails: org.selectors.selectOrgDetails(state),
});

const mapDispatchToProps = (dispatch) => ({
  updateAddress: (payload) => dispatch(org.actions.updateAddress(payload)),
  openAlert: (payload) => dispatch(alert.actions.open(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddressSection);
