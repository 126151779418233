import React from 'react';
import { Typography, Box, Divider, Grid } from '@material-ui/core';

import SEO from '../../seo';
import LinkSection from './LinkSection';
// import ExampleSection from './ExampleSection';

const Dashboard = () => {
  return (
    <>
      <SEO title="Marketing" />
      <Box pb={2}>
        <Typography variant="h5">Marketing</Typography>
      </Box>
      <Typography variant="body2">
        Use the URLs provided below to share with your supporters via various
        channels.
      </Typography>
      <Divider />
      <Box py={2}>
        <Grid container direction="row" spacing={4}>
          <Grid item xs={12} md={6}>
            <LinkSection />
          </Grid>
          {/* <Grid item xs={12} md={6}>
            <ExampleSection />
          </Grid> */}
        </Grid>
      </Box>
    </>
  );
};

export default Dashboard;
