import React from 'react';
import { Typography, Box } from '@material-ui/core';

const ComingSoon = () => {
  return (
    <Box>
      <Typography variant="body1">Coming Soon!</Typography>
      <Typography variant="body2">
        We are currently working on this feature.
      </Typography>
    </Box>
  );
};

export default ComingSoon;
