import React from 'react';
import PropTypes from 'prop-types';
import { getOr } from 'lodash/fp';
import { Grid, Card, CardContent, CardHeader } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { ResponsiveBar } from '@nivo/bar';

const colors = [
  '#353531',
  '#EC4E20',
  '#FF9505',
  '#014776',
  '#3C153B',
  '#89BD9E',
  '#DB4C40',
  '#8B1E3F',
  '#C2714F',
  '#D36060',
  '#93E1D8',
  '#DDFFF7',
];

const ChartSection = ({ orgStats, isLoading }) => {
  const fiveMerchants = getOr([], 'topFiveMerchants', orgStats);
  const fiveMonths = getOr([], 'lastFiveMonths', orgStats);

  const fiveMerchantsWithColors = fiveMerchants.map((el, i) => ({
    ...el,
    color: colors[i],
  }));
  const fiveMonthsWithColors = fiveMonths.map((el, i) => ({
    ...el,
    color: colors[i],
  }));

  const getColor = (bar) => bar.data.color;

  const commonProps = {
    height: 300,
    margin: { top: 5, right: 10, bottom: 70, left: 50 },
    borderRadius: '5px',
    padding: 0.85,
    colors: getColor,
    keys: ['cardsSold'],
    labelTextColor: 'inherit:darker(1.4)',
    labelSkipHeight: 1,
    enableLabel: false,
    tooltip: ({ indexValue, value, color }) => (
      <strong style={{ color }}>
        {indexValue}: {value}
      </strong>
    ),
  };
  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={6}>
        <Card elevation={3}>
          <CardHeader
            title="Cards Sold Per Month"
            titleTypographyProps={{ color: 'textSecondary' }}
          />
          <CardContent style={{ height: '300px' }}>
            {isLoading ? (
              <Skeleton
                height="100%"
                width="100%"
                style={{ transform: 'none' }}
              />
            ) : (
              <ResponsiveBar
                {...commonProps}
                indexBy="month"
                data={fiveMonthsWithColors}
              />
            )}
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={6}>
        <Card elevation={3}>
          <CardHeader
            title="Top 5 Merchants"
            titleTypographyProps={{ color: 'textSecondary' }}
          />
          <CardContent style={{ height: '300px' }}>
            {isLoading ? (
              <Skeleton
                height="100%"
                width="100%"
                style={{ transform: 'none' }}
              />
            ) : (
              <ResponsiveBar
                {...commonProps}
                axisBottom={false}
                indexBy="name"
                data={fiveMerchantsWithColors}
              />
            )}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

ChartSection.defaultProps = {
  indexValue: '',
  value: '',
  color: '',
};

ChartSection.propTypes = {
  indexValue: PropTypes.oneOfType([PropTypes.any]),
  value: PropTypes.oneOfType([PropTypes.any]),
  color: PropTypes.oneOfType([PropTypes.any]),
  isLoading: PropTypes.bool.isRequired,
  orgStats: PropTypes.shape({
    topFiveMerchants: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        cardsSold: PropTypes.number,
      })
    ),
    lastFiveMonths: PropTypes.arrayOf(
      PropTypes.shape({
        month: PropTypes.string,
        cardsSold: PropTypes.number,
      })
    ),
  }).isRequired,
};

export default ChartSection;
