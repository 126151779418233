import React from 'react';
import { Router, navigate, useLocation } from '@reach/router';
import { useDispatch, useSelector } from 'react-redux';

import {
  Dashboard as DashboardRoute,
  Profile,
  Reports,
  Marketing,
  Login,
} from '../components/routes';
import PrivateRoute from '../components/PrivateRoute';
import { SidebarHeader, Content } from '../components/layouts';
import { auth, org } from '../state';
import { PreLoader } from '../components/custom';

const Dashboard = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) =>
    auth.selectors.selectIsLoggedIn(state)
  );

  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    if (!isLoggedIn) {
      const response = dispatch(auth.actions.refreshToken());
      response.then(({ payload }) => {
        dispatch(org.actions.getOrgDetails(payload));
        setIsLoading(false);
      });
    }
  }, []);

  if (isLoading) {
    return <PreLoader />;
  }

  if (isLoggedIn && location.pathname === '/dashboard/login') {
    navigate('/dashboard');
    return null;
  }

  return (
    <>
      {isLoggedIn ? <SidebarHeader /> : null}
      <Content withSidebar={isLoggedIn} md={12}>
        <Router basepath="/dashboard">
          <PrivateRoute path="/" component={DashboardRoute} />
          <PrivateRoute path="/profile" component={Profile} />
          <PrivateRoute path="/marketing" component={Marketing} />
          <PrivateRoute path="/reports" component={Reports} />
          <Login path="/login" />
        </Router>
      </Content>
    </>
  );
};

export default Dashboard;
